.notFound {
  width: 50%;
  margin: auto;
  text-align: center;
  padding: 50px;
}
.sorryTxt {
  font-size: 24px;
  margin-bottom: 30px;
  color: #666666;
}
